import React from 'react';

import Content from '../../../components/Pages/Admin/Dashboard';
import { displayName } from '../../../config';
import Layout from '../../../components/Layout/Main';

const Dashboard = (): JSX.Element => {
  document.title = `${displayName}: Dashboard`;

  return (
    <Layout title="Dashboard Home">
      <Content />
    </Layout>
  );
};

export default Dashboard;
