import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Content from '../../../components/Pages/Auth/SignUp';
import { displayName } from '../../../config';
import Layout from '../../../components/Layout/Auth';

const SignUp = (): JSX.Element => {
  document.title = `${displayName}: Sign Up`;
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Layout>
        <Content />
      </Layout>
    </GoogleOAuthProvider>
  );
};

export default SignUp;
