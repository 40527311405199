import Layout from '../../../components/Layout/Main';
import Content from '../../../components/Pages/Errors/ComingSoon';
import { displayName } from '../../../config';

const ComingSoon = (): JSX.Element => {
  document.title = `${displayName}: Coming Soon`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default ComingSoon;
