import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { Action } from '../index';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse } from './common';
import { IPackage, IPeriod } from '../../types/customer';
import { fetchAuthSaga } from './auth';

/**
 * Constants
 * */
export const moduleName = 'customer';
const prefix = `${appName}/${moduleName}`;

export const CUSTOMER_START = `${prefix}/CUSTOMER_START`;
export const CUSTOMER_SUCCESS = `${prefix}/CUSTOMER_SUCCESS`;
export const CUSTOMER_ERROR = `${prefix}/CUSTOMER_ERROR`;
export const CUSTOMER_ERROR_RESET = `${prefix}/CUSTOMER_ERROR_RESET`;

export const GET_CUSTOMER_PERIOD = `${prefix}/GET_CUSTOMER_PERIOD`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  error: Error | null;
  period: IPeriod | null;
  package: IPackage | null;
}

const localState: State = {
  loading: false,
  error: null,
  period: null,
  package: null,
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_START:
      return { ...state, loading: true, error: null };
    case CUSTOMER_SUCCESS:
      return { ...state, loading: false, ...payload };
    case CUSTOMER_ERROR:
      return { ...state, loading: false, error: payload };
    case CUSTOMER_ERROR_RESET:
      return { ...state, loading: false, error: null };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */

/**
 * Action Creators
 * */

export interface ICustomerParams {
  customerId: number;
  periodId?: number;
}
export interface ICustomerPayload {
  payload: ICustomerParams;
}

export const getCustomerPeriod = (payload: ICustomerParams): Action => ({
  type: GET_CUSTOMER_PERIOD,
  payload,
});

/**
 * Sagas
 */
export function* getCustomerPeriodSaga({ payload: { customerId, periodId } }: ICustomerPayload): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  const response = (yield call(
    fetchAuthSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/app/client/${customerId}/period${periodId ? `/${periodId}` : ''}`,
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CUSTOMER_SUCCESS, CUSTOMER_ERROR, false, (period) => ({ period }));
}

export function* saga(): Generator {
  yield takeLatest(
    GET_CUSTOMER_PERIOD,
    cancelableLocationSaga.bind(null, getCustomerPeriodSaga, CUSTOMER_ERROR, false),
  );

  yield takeLatest(CUSTOMER_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: CUSTOMER_ERROR_RESET,
    });
  });
}
