import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { Action } from '../index';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse } from './common';
import { getDataInStorage } from '../../utils/storage';
import { RootState } from '../reducers';
import {
  CLIENT_EMAILS,
  CLIENT_FIRST_NAME_ARRAY,
  CLIENT_LAST_NAME_ARRAY,
  CLIENT_PASSWORDS,
  CUSTOMER_ID,
} from '../constants';
import { IUser } from '../../types/entries';

/**
 * Constants
 * */

export const moduleName = 'auth';
const prefix = `${appName}/${moduleName}`;

export const SIGN_IN = `${prefix}/SIGN_IN`;
export const SIGN_IN_BY_TOKEN = `${prefix}/SIGN_INSIGN_IN_BY_TOKEN`;
export const SIGN_IN_START = `${prefix}/SIGN_IN_START`;
export const SIGN_IN_SUCCESS = `${prefix}/SIGN_IN_SUCCESS`;
export const SIGN_IN_ERROR = `${prefix}/SIGN_IN_ERROR`;
export const SIGN_IN_ERROR_RESET = `${prefix}/SIGN_IN_ERROR_RESET`;
export const SIGN_OUT = `${prefix}/SIGN_OUT`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  error: Error | null;
  authorized: boolean;
  user: IUser | null;
  accessToken: string | null;
  refreshToken: string | null;
}

const localState: State = {
  loading: false,
  error: null,
  authorized: false,
  user: null,
  accessToken: null,
  refreshToken: null,
  ...getDataInStorage(moduleName),
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case SIGN_IN_START:
      return { ...state, loading: true, error: null };
    case SIGN_IN_SUCCESS:
      return { ...state, loading: false, authorized: !!payload.accessToken, ...payload };
    case SIGN_IN_ERROR:
      return { ...state, loading: false, error: payload };
    case SIGN_IN_ERROR_RESET:
      return { ...state, loading: false, error: null };

    case SIGN_OUT:
      return { ...state, authorized: false, user: null, accessToken: null, refreshToken: null };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */
export interface ISignIn {
  email: string;
  password: string;
}

/**
 * Action Creators
 * */
export const signIn = ({ email, password }: ISignIn): Action => ({
  type: SIGN_IN,
  payload: { email, password },
});

export const signInByToken = (token: string): Action => ({
  type: SIGN_IN_BY_TOKEN,
  payload: { token },
});

export const signOut = (): Action => ({
  type: SIGN_OUT,
});

const getUser = ({ email, password }: ISignIn): IUser | undefined => {
  let user;

  CLIENT_EMAILS.forEach((login, index) => {
    if (email === login.trim() && CLIENT_PASSWORDS[index].trim() === password) {
      user = {
        id: CUSTOMER_ID,
        email: login.trim(),
        firstName: CLIENT_FIRST_NAME_ARRAY[index].trim(),
        lastName: CLIENT_LAST_NAME_ARRAY[index].trim(),
      };
    }
  });

  return user;
};

/**
 * Sagas
 */
export function* signInSaga({ payload }: { payload: ISignIn }): Generator {
  yield put({
    type: SIGN_IN_START,
  });

  yield delay(2000);

  const user = getUser(payload);

  if (user) {
    yield put({
      type: SIGN_IN_SUCCESS,
      payload: {
        accessToken: process.env.REACT_APP_ACCESS_TOKEN,
        user,
      },
    });
  } else {
    yield put({
      type: SIGN_IN_ERROR,
      payload: { message: 'Incorrect login or password!' },
    });
  }
}

export function* signInByTokenSaga({ payload: { token } }: { payload: { token: string } }): Generator {
  yield put({
    type: SIGN_IN_START,
  });

  const response = (yield call(
    fetchAuthSaga,
    'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
    {},
    {
      token,
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(response, SIGN_IN_SUCCESS, SIGN_IN_ERROR, false, (data) => ({
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    user: {
      id: CUSTOMER_ID,
      email: data.email,
      firstName: data.given_name,
      lastName: data.family_name,
      avatar: data.picture,
    },
  }));
}

export function* updateStorageSaga(): Generator {
  yield delay(100);

  const data = yield select((state: RootState) => state[moduleName]);

  localStorage.setItem(moduleName, JSON.stringify(data));
}

/**
 * @param {String} url
 * @param {Object} init
 * @param {Object} options
 *
 * @returns {IterableIterator<Promise<Response>*>}
 */
export function* fetchAuthSaga(
  url: string,
  init: RequestInit = {},
  options: { [key: string]: boolean | string } = {},
): Generator {
  const { accessToken } = (yield select((state: RootState) => state[moduleName])) as State;
  const newInit = {
    credentials: 'same-origin',
    ...init,
  };

  newInit.headers = {
    Accept: 'application/json',
    ...(newInit.headers || {}),
  };

  if ((options?.token || accessToken) && options?.authorization !== false) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newInit.headers.Authorization = `Bearer ${options?.token || accessToken}`;
  }

  return yield fetch(url, newInit as RequestInit);
}

export function* saga(): Generator {
  yield takeLatest(SIGN_IN, cancelableLocationSaga.bind(null, signInSaga, SIGN_IN_ERROR, false));
  yield takeLatest(SIGN_IN_BY_TOKEN, cancelableLocationSaga.bind(null, signInByTokenSaga, SIGN_IN_ERROR, false));
  yield takeLatest(SIGN_IN_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: SIGN_IN_ERROR_RESET,
    });
  });

  yield takeLatest([SIGN_IN_SUCCESS, SIGN_OUT], updateStorageSaga);
}
