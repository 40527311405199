import React from 'react';

import Content from '../../../../components/Pages/Customer/Records/Details';
import { displayName } from '../../../../config';
import Layout from '../../../../components/Layout/Main';

const RecordDetails = (): JSX.Element => {
  document.title = `${displayName}: Recordings`;

  return (
    <Layout withBackButton title="Recordings">
      <Content />
    </Layout>
  );
};

export default RecordDetails;
