import { CSSProperties } from 'react';

/* eslint-disable max-len */

interface Svg {
  children: React.ReactNode;
  className?: string;
  viewBox?: string;
  style?: CSSProperties;
}

export const Svg = ({ children, className, viewBox, ...props }: Svg): JSX.Element => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    height="1em"
    width="1em"
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
  </svg>
);

Svg.defaultProps = {
  className: '',
  viewBox: '0 0 24 24',
  style: {},
};

interface AnyProps {
  // eslint-disable-next-line
  [key: string]: any;
}

export const IconNotFoundContent = ({ ...props }: AnyProps): JSX.Element => (
  <svg width="48" height="30" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_108108_402265)">
      <path
        d="M32 39.7037C49.6731 39.7037 64 36.5929 64 32.7557C64 28.9184 49.6731 25.8076 32 25.8076C14.3269 25.8076 0 28.9184 0 32.7557C0 36.5929 14.3269 39.7037 32 39.7037Z"
        fill="#E9F2EC"
      />
      <path
        d="M55 13.6658L44.854 2.24915C44.367 1.47097 43.656 1.00049 42.907 1.00049H21.093C20.344 1.00049 19.633 1.47097 19.146 2.24816L9 13.6668V22.8372H55V13.6658Z"
        fill="#E9F2EC"
        stroke="#1F803F"
      />
      <path
        d="M41.613 16.8133C41.613 15.2202 42.607 13.905 43.84 13.9041H55V31.9064C55 34.0137 53.68 35.7407 52.05 35.7407H11.95C10.32 35.7407 9 34.0127 9 31.9064V13.9041H20.16C21.393 13.9041 22.387 15.2172 22.387 16.8103V16.8322C22.387 18.4252 23.392 19.7116 24.624 19.7116H39.376C40.608 19.7116 41.613 18.4133 41.613 16.8202V16.8133Z"
        fill="white"
        stroke="#1F803F"
      />
    </g>
    <defs>
      <clipPath id="clip0_108108_402265">
        <rect width="64" height="40" fill="white" transform="translate(0 0.000488281)" />
      </clipPath>
    </defs>
  </svg>
);

export const IconGoogle = ({ ...props }: AnyProps): JSX.Element => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
    <path d="M1 1h22v22H1z" fill="none" />
  </Svg>
);

export default Svg;
