import React from 'react';

import Content from '../../../components/Pages/Customer/Records';
import { displayName } from '../../../config';
import Layout from '../../../components/Layout/Main';

const Records = (): JSX.Element => {
  document.title = `${displayName}: Recordings`;

  return (
    <Layout title="Recordings" withBackButton>
      <Content />
    </Layout>
  );
};

export default Records;
