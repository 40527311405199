import React from 'react';
import clsx from 'clsx';
import { Typography } from 'antd';

import styles from './index.module.less';

interface ITag {
  label: string;
  type: string;
}

const Tag: React.FC<ITag> = ({ label, type }) => (
  <div
    className={clsx(styles.tag, {
      [styles.positive]: type.toLowerCase().includes('positive'),
      [styles.neutral]: type.toLowerCase().includes('neutral'),
      [styles.negative]: type.toLowerCase().includes('negative'),
    })}
  >
    <Typography.Text className="fs-12 fw-400 ws-nowrap lh-1 color-white">{label}</Typography.Text>
  </div>
);

export default Tag;
