import React from 'react';
import { Button, Collapse, ModalFuncProps, Switch, Typography } from 'antd';
import { useContextNotifications } from '../../../../../../context/notifications';

const { Panel } = Collapse;

const text = `
  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
  standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
  a type specimen book.
`;
const Content = ({ openConfirm }: { openConfirm: ((props: ModalFuncProps) => void) | null }) => (
  <>
    <p>{text}</p>
    <p>{text}</p>
    <div className="flex-row flex-justify-space-between flex-align-center w-full">
      <Button
        type="link"
        className="color-error p-0 flex-row gap-10 flex-align-center"
        onClick={() =>
          openConfirm?.({
            title: 'Delete',
            content: 'Are you sure you want to delete this item?',
            okText: 'Delete',
          })
        }
      >
        <span className="icon-bin" />
        <Typography className="color-error">Delete?</Typography>
      </Button>
      <div className="flex-row gap-15">
        <Typography>Enabled?</Typography>
        <Switch defaultChecked />
      </div>
    </div>
  </>
);

const Introduction = () => {
  const { openConfirm } = useContextNotifications();

  return (
    <div className="flex-column gap-10 w-full">
      <Collapse expandIconPosition="end">
        <Panel header="Content Summary/Title" key="1">
          <Content openConfirm={openConfirm || null} />
        </Panel>
      </Collapse>
      <Collapse expandIconPosition="end">
        <Panel header="Content Summary/Title" key="2">
          <Content openConfirm={openConfirm || null} />
        </Panel>
      </Collapse>
      <Collapse expandIconPosition="end">
        <Panel header="Content Summary/Title" key="3">
          <Content openConfirm={openConfirm || null} />
        </Panel>
      </Collapse>
      <Collapse expandIconPosition="end">
        <Panel header="Content Summary/Title" key="4">
          <Content openConfirm={openConfirm || null} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default Introduction;
