import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Image, Typography } from 'antd';

import { connect } from 'react-redux';

import styles from '../index.module.less';
import { IUser } from '../../../../types/entries';
import { RootState } from '../../../../store/reducers';
import { moduleName as authModuleName } from '../../../../store/ducks/auth';
import sideMenuRoutes from '../../../../routes/sideMenu';

interface IMenuCommon {
  user: IUser | null;
}
const MenuCommon: React.FC<IMenuCommon> = ({ user }) => {
  const { pathname } = useLocation();

  const [key, setKey] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);

  const handleToggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const filteredRoutes = sideMenuRoutes.filter((route) => !!route.adminRoute === !!user?.isAdmin);

  return (
    <>
      <div className={clsx(styles.substrate, { [styles.expanded]: isExpanded })} onClick={handleToggleExpanded} />
      <div className={clsx(styles.sidebarContainer, { [styles.expanded]: isExpanded })}>
        <Link className={clsx(styles.menuLink, styles.logoLink)} to="/">
          <div>
            <Image className={styles.logo} width={40} height={35} src="/logo_small.png" preview={false} />
          </div>
          <Typography.Title level={3} className={styles.menuLinkText}>
            Neural Voice
          </Typography.Title>
        </Link>
        <nav className={clsx(styles.menuContainer)}>
          <ol className={styles.menuList}>
            {filteredRoutes.map((route) => (
              <li
                className={clsx(styles.menuItem, { [styles.active]: route.bind.path === `/${key}` })}
                key={route.name}
              >
                <Link className={styles.menuLink} to={route.bind.path || '/'}>
                  <Typography className={styles.menuLinkIcon}>{route.icon}</Typography>
                  <Typography className={styles.menuLinkText}>{route.name}</Typography>
                </Link>
              </li>
            ))}
          </ol>
          <div className={clsx(styles.menuItem, { [styles.active]: `/${key}` === '/settings' })}>
            <Link className={styles.menuLink} to="/settings">
              <span className={clsx('icon-settings', styles.menuLinkIcon)} />
              <Typography className={styles.menuLinkText}>Settings</Typography>
            </Link>
          </div>
          <div className={styles.expandButtonWrapper}>
            <Button
              type="text"
              onClick={handleToggleExpanded}
              className={clsx(styles.expandButton, { [styles.expanded]: isExpanded })}
            >
              <span className={clsx('icon-expand-sidebar', styles.menuLinkIcon)} />
            </Button>
          </div>
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
});

export default connect(mapStateToProps)(MenuCommon);
