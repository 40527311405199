import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';

import styles from './index.module.less';

const Simple: React.FC<PropsWithChildren> = ({ children }) => {
  const { Content } = Layout;

  return (
    <Layout className={styles.layout}>
      <Content>
        <div className={styles.container}>{children}</div>
      </Content>
    </Layout>
  );
};

export default Simple;
