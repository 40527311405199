import React from 'react';

import Content from '../../../components/Pages/Auth/ResetPassword';
import { displayName } from '../../../config';
import Layout from '../../../components/Layout/Auth';

const ResetPassword = (): JSX.Element => {
  document.title = `${displayName}: Reset Password`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default ResetPassword;
