import { combineReducers, Reducer } from 'redux';
import local, { State as localState, moduleName as localModule } from './ducks/local';
import auth, { State as authState, moduleName as authModule } from './ducks/auth';
import characters, { State as charactersState, moduleName as charactersModule } from './ducks/characters';
import records, { State as recordsState, moduleName as recordsModule } from './ducks/records';
import customer, { State as customerState, moduleName as customerModule } from './ducks/customer';
import client, { State as clientState, moduleName as clientModule } from './ducks/client';

export interface RootState {
  [localModule]: localState;
  [authModule]: authState;
  [charactersModule]: charactersState;
  [recordsModule]: recordsState;
  [customerModule]: customerState;
  [clientModule]: clientState;
}

export default function AppReducer(): Reducer {
  return combineReducers({
    [localModule]: local,
    [authModule]: auth,
    [charactersModule]: characters,
    [recordsModule]: records,
    [customerModule]: customer,
    [clientModule]: client,
  });
}
