import React from 'react';
import { Button, Typography } from 'antd';

import styles from '../../index.module.less';
import { copyTextToClipboard } from '../../../../../../utils/text';
import { useContextNotifications } from '../../../../../../context/notifications';
import { ICharacter } from '../../../../../../types/entries';

interface IScriptTab {
  character: ICharacter | null;
}

const ScriptTab: React.FC<IScriptTab> = ({ character }) => {
  const { openNotification } = useContextNotifications();
  const code = `<script>
<!-- embed script here -->
</script>
`;

  return (
    <div className="flex-column gap-20">
      <Typography className="fs-16 lh-1 color-white m-0 fw-400">
        To use {character?.name} on your website or app, please paste the below script immediately before the closing
        &#x3c;/body&#x3e; tag on your site
      </Typography>
      <div className={styles.scriptContainer}>
        <Typography className={styles.code}>{code}</Typography>
        <div className={styles.controlsRow}>
          <Button
            type="primary"
            onClick={() =>
              copyTextToClipboard(code).then(() => {
                openNotification?.({ message: 'The code is copied to the clipboard' });
              })
            }
          >
            Copy Code
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ScriptTab;
