import React, { PropsWithChildren } from 'react';

import Simple from '../Simple';

import styles from './index.module.less';

const Auth: React.FC<PropsWithChildren> = ({ children }) => (
  <Simple>
    <div className={styles.content}>{children}</div>
  </Simple>
);

export default Auth;
