import React from 'react';

import Content from '../../../../components/Pages/Customer/Characters/Details';
import { displayName } from '../../../../config';
import Layout from '../../../../components/Layout/Main';

const CharacterDetails = (): JSX.Element => {
  document.title = `${displayName}: Manage Character`;

  return (
    <Layout title="Manage Character" withBackButton>
      <Content />
    </Layout>
  );
};

export default CharacterDetails;
