import React from 'react';
import { Button, Typography } from 'antd';
import moment from 'moment-timezone';

import { ProColumns } from '@ant-design/pro-table';
import AudioWave from '../../../../../Common/AudioWave';
import { IRecord } from '../../../../../../types/entries';
import Tag from '../../../../../Common/Tag';
import { useContextNotifications } from '../../../../../../context/notifications';
import { Action } from '../../../../../../store';
import { IDeleteRecord } from '../../../../../../store/ducks/records';
import Table from '../../../../../Common/Table';

interface IRecordInfo {
  record?: IRecord | null;
  deleteRecord: (payload: IDeleteRecord) => Action;
}

const RecordInfo: React.FC<IRecordInfo> = ({ record, deleteRecord }) => {
  const { openConfirm } = useContextNotifications();

  const handleDelete = (rec: IRecord | null) => {
    if (!rec || !rec.id) return;

    deleteRecord({ id: rec.id, characterId: rec.handler.id });
  };

  const columns: ProColumns<IRecord>[] = [
    {
      title: 'Date',
      width: 100,
      render: (_, row) => (
        <Typography.Text className="color-white fs-12 fw-400 ws-nowrap lh-1">
          {moment(row.date).format('DD MMM, YYYY')}
        </Typography.Text>
      ),
    },
    {
      title: 'Time',
      width: 100,
      render: (_, row) => (
        <Typography.Text className="color-white fs-12 fw-400 ws-nowrap lh-1">
          {moment(row.date).format('HH:mm')}
        </Typography.Text>
      ),
    },
    {
      title: 'Playback',
      width: 'auto',
      dataIndex: 'audio',
      render: (_, row) => <AudioWave id={row.id} source={row.audio} width="100%" height={40} />,
    },
    {
      title: 'Sentiment',
      width: 100,
      render: (_, row) => <Tag label={row.sentiment} type={row.sentiment} />,
    },
    {
      width: 40,
      render: (_, row) => (
        <Button
          className="btn-danger icon-button"
          type="text"
          onClick={() =>
            openConfirm?.({
              title: 'Delete',
              content: 'Are you sure you want to delete this record?',
              okText: 'Delete',
              okType: 'danger',
              onOk: () => handleDelete(row),
            })
          }
        >
          <span className="icon-bin color-white fs-14" />
        </Button>
      ),
    },
  ];

  return (
    <Table<IRecord>
      dataSource={record ? [record] : []}
      columns={columns}
      toolBarRender={false}
      search={false}
      pagination={false}
    />
  );
};

RecordInfo.defaultProps = {
  record: undefined,
};

export default RecordInfo;
