import React, { useEffect, useState } from 'react';
import { Avatar, Typography } from 'antd';
import clsx from 'clsx';
import { ProColumns } from '@ant-design/pro-table';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { UserOutlined } from '@ant-design/icons';
import ContentCard from '../ContentCard';
import Table from '../Table';
import { ICharacter } from '../../../types/entries';
import Pagination from '../Pagination';
import { pageSize } from '../../../config';
import { RootState } from '../../../store/reducers';
import {
  getCharacters as getCharactersAction,
  IParams,
  moduleName as charactersModuleName,
} from '../../../store/ducks/characters';
import { Action } from '../../../store';

interface ICharacterPerformance {
  characterId?: string;
  characters: ICharacter[];
  total: number;
  getCharacters: (params?: IParams) => Action;
  extraColumn?: ProColumns<ICharacter>;
}
const CharacterPerformance: React.FC<ICharacterPerformance> = ({
  characterId,
  characters,
  total,
  getCharacters,
  extraColumn,
}) => {
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getCharacters({ id: characterId, page, size: pageSize });
  }, [page]);

  const columns: ProColumns<ICharacter>[] = [
    {
      title: '',
      width: 60,
      dataIndex: 'image',
      renderText: (image) => (
        <Avatar className="border-1 border-color-aurichalcite" size={60} icon={<UserOutlined />} src={image} />
      ),
    },
    {
      title: '',
      width: 'auto',
      render: (_, row) => (
        <div className="flex-column gap-4">
          <Typography.Text className="color-white fs-16 fw-500 lh-1">{row.name}</Typography.Text>
          <Typography.Text className="color-white fs-12 fw-400 ws-nowrap lh-1">{row.purpose}</Typography.Text>
        </div>
      ),
    },
    {
      title: 'Empty Calls',
      width: 120,
      align: 'center',
      dataIndex: 'emptyCount',
      renderText: (emptyCount) => (
        <Typography.Text className="color-aurichalcite fs-24 lh-1 fw-500 ws-nowrap">{emptyCount}</Typography.Text>
      ),
    },
    {
      title: 'Calls Handled',
      width: 120,
      align: 'center',
      dataIndex: 'successCount',
      renderText: (successCount) => (
        <Typography.Text className="color-aurichalcite fs-24 lh-1 fw-500 ws-nowrap">{successCount}</Typography.Text>
      ),
    },
    {
      title: 'Total Calls',
      width: 120,
      align: 'center',
      dataIndex: 'totalCount',
      renderText: (totalCount) => (
        <Typography.Text className="color-aurichalcite fs-24 lh-1 fw-500 ws-nowrap">{totalCount}</Typography.Text>
      ),
    },
    {
      title: '% Positive',
      width: 100,
      align: 'center',
      dataIndex: 'rating',
      renderText: (rating) => (
        <Typography.Text
          className={clsx('fs-24 lh-1 fw-500 ws-nowrap', {
            'color-success': rating >= 90,
            'color-blue': rating < 90 && rating >= 50,
            'color-error': rating < 50,
          })}
        >
          {rating}%
        </Typography.Text>
      ),
    },
    // {
    //   title: 'Status',
    //   width: 80,
    //   align: 'center',
    //   dataIndex: 'isActive',
    //   renderText: (isActive: boolean) => <Switch defaultChecked={isActive} />,
    // },
  ];

  if (extraColumn) {
    columns.push(extraColumn);
  }

  return (
    <ContentCard title="Character Performance" align="left">
      <div className="w-full flex-column gap-20">
        <Table<ICharacter>
          dataSource={characters || []}
          columns={columns}
          toolBarRender={false}
          search={false}
          pagination={false}
        />
        {!characterId && <Pagination page={page} onChange={setPage} total={total} pageSize={pageSize} />}
      </div>
    </ContentCard>
  );
};

CharacterPerformance.defaultProps = {
  characterId: undefined,
  extraColumn: undefined,
};

const mapStateToProps = (state: RootState) => ({
  characters: state[charactersModuleName].charactersList,
  total: state[charactersModuleName].charactersTotal,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCharacters: (params?: IParams) => dispatch(getCharactersAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CharacterPerformance);
