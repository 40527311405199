import React from 'react';
import { Typography } from 'antd';

import styles from '../../index.module.less';
import { JsonResult } from '../../../../../../types';
import { prettyTextWithUnderscores } from '../../../../../../utils/text';

interface ICollectedDataTab {
  collectedData?: JsonResult;
}

const CollectedDataTab: React.FC<ICollectedDataTab> = ({ collectedData }) =>
  collectedData ? (
    <div className={styles.dataBox}>
      {Object.entries(collectedData).map(([key, value]) => (
        <>
          <Typography key={key} className="color-white fs-12">
            {prettyTextWithUnderscores(key)}
          </Typography>
          <Typography key={value} className="color-white fs-12">
            {value}
          </Typography>
        </>
      ))}
    </div>
  ) : (
    <div className="flex-column flex-align-center flex-justify-center">
      <Typography className="color-white">No collected data yet</Typography>
    </div>
  );

CollectedDataTab.defaultProps = {
  collectedData: undefined,
};

export default CollectedDataTab;
