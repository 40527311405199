import React, { useEffect } from 'react';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Image, Input, Typography } from 'antd';
import { useGoogleLogin } from '@react-oauth/google';

import { login, password } from '../../../../utils/inputRules';
import { RootState } from '../../../../store/reducers';
import {
  ISignIn,
  moduleName as authModuleName,
  signIn as signInAction,
  signInByToken as signInByTokenAction,
} from '../../../../store/ducks/auth';

import styles from '../index.module.less';
import { IconGoogle } from '../../../Common/Icon';
import { useContextNotifications } from '../../../../context/notifications';
import { alertDelayError } from '../../../../config';

interface ISignInComponent {
  loading: boolean;
  authorized: boolean;
  error: Error | null;
  signIn: (data: ISignIn) => Action;
  signInByToken: (token: string) => Action;
}
const SignIn: React.FC<ISignInComponent> = ({ loading, authorized, error, signIn, signInByToken }) => {
  const { openNotification } = useContextNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authorized) return;

    navigate('/');
  }, [authorized]);

  useEffect(() => {
    if (!error) return;

    openNotification?.({ message: error.message, type: 'error', duration: alertDelayError / 1000 });
  }, [error]);

  // const handleGoogleSuccess = (credentialResponse: CredentialResponse) => {
  //   // eslint-disable-next-line
  //   console.log(2, credentialResponse);
  // };
  //
  // useGoogleOneTapLogin({
  //   onSuccess: handleGoogleSuccess,
  //   auto_select: true,
  // });

  const handleSignIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      if (tokenResponse.hd === 'neuralriver.ai' || tokenResponse.hd === 'neural-voice.ai') {
        signInByToken(tokenResponse.access_token);
      }
    },
  });

  return (
    <div className={styles.container}>
      <Image width={273} height={42} src="/logo.png" preview={false} />
      <Typography.Title className={styles.title}>Log in to Neural Voice</Typography.Title>
      <Button
        size="large"
        type="text"
        onClick={() => handleSignIn()}
        className={styles.googleButton}
        icon={<IconGoogle />}
      >
        Sign in with Google
      </Button>
      <div className={styles.divider}>
        <div className={styles.line} />
        <Typography className={styles.text}>or sign in with email</Typography>
        <div className={styles.line} />
      </div>
      <Form layout="vertical" className={styles.form} onFinish={signIn} labelWrap>
        <Form.Item name="email" label="Email" rules={login} validateStatus={error ? 'error' : 'success'}>
          <Input size="large" />
        </Form.Item>
        <Form.Item className={styles.passwordContainer}>
          <Form.Item name="password" label="Password" rules={password} validateStatus={error ? 'error' : 'success'}>
            <Input.Password size="large" />
          </Form.Item>
          <Link className={styles.forgotPassword} to="/forgot-password">
            Forgot?
          </Link>
        </Form.Item>
        <Form.Item noStyle>
          <Button size="large" type="text" htmlType="submit" className={styles.submitButton} loading={loading}>
            Log In
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        {/* <Typography className={styles.link}> */}
        {/*  Don't have an account?{' '} */}
        {/*  <Link className={styles.signIn} to="/sign-up"> */}
        {/*    Sign Up */}
        {/*  </Link> */}
        {/* </Typography> */}
        <Link className={styles.link} target="_blank" to={process.env.REACT_APP_NEURAL_VOICE_URL || ''}>
          Return to Neural Voice
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  authorized: state[authModuleName].authorized,
  error: state[authModuleName].error,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (data: ISignIn) => dispatch(signInAction(data)),
  signInByToken: (token: string) => dispatch(signInByTokenAction(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
