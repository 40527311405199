import React from 'react';
import { Avatar, Button, Switch, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { UserOutlined } from '@ant-design/icons';
import ContentCard from '../../../../Common/ContentCard';
import { ICharacter } from '../../../../../types/entries';

import styles from './index.module.less';

interface ICharacterTile {
  character: ICharacter;
}

const CharacterTile: React.FC<ICharacterTile> = ({ character }) => {
  const { id, name, isActive, purpose, successCount, rating, emptyCount, totalCount } = character;

  return (
    <ContentCard gap="1rem" borderRadius="1rem" align="center">
      <div className="w-full flex-row flex-justify-end">
        <Switch defaultChecked={isActive} />
      </div>
      <div className="flex-column gap-8 flex-align-center">
        <Avatar
          className="border-1 border-color-aurichalcite"
          size={100}
          icon={<UserOutlined />}
          src={character.image}
        />
        <Typography.Title className="color-white fs-24 m-0" level={2}>
          {name}
        </Typography.Title>
      </div>
      <Typography className="color-white fs-20">{purpose}</Typography>

      <div className="grid grid-cols-2 gap-16">
        <div className={styles.statsBox}>
          <Typography className="color-white fs-16 ws-nowrap">Empty Calls</Typography>
          <Typography className="color-blue fs-24">{emptyCount}</Typography>
        </div>
        <div className={styles.statsBox}>
          <Typography className="color-white fs-16 ws-nowrap">Calls Handled</Typography>
          <Typography className="color-blue fs-24">{successCount}</Typography>
        </div>
        <div className={styles.statsBox}>
          <Typography className="color-white fs-16 ws-nowrap">Total Calls</Typography>
          <Typography className="color-blue fs-24">{totalCount}</Typography>
        </div>
        <div className={styles.statsBox}>
          <Typography className="color-white fs-16 ws-nowrap">% Positive</Typography>
          <Typography className="color-blue fs-24">{rating}</Typography>
        </div>
      </div>
      <Link to={`/characters/${id}`} className="mt-8 text-decoration-none">
        <Button className={styles.manageButton}>Manage</Button>
      </Link>
    </ContentCard>
  );
};

export default CharacterTile;
