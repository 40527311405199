import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { getAuth, signInAnonymously } from 'firebase/auth';
import { initializeApp, getApp } from 'firebase/app';
import { store } from './store';

import Routes from './routes';

import './App.less';
import NotificationsProvider from './context/notifications';
import { firebaseConfig } from './config';

export const getFirebaseApp = () => {
  try {
    return getApp();
  } catch {
    return initializeApp(firebaseConfig);
  }
};

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
    }

    const firebaseApp = getFirebaseApp();
    const firebaseAuth = getAuth(firebaseApp);

    signInAnonymously(firebaseAuth);
  }, []);

  return (
    <Provider store={store}>
      <ConfigProvider locale={enUS}>
        <NotificationsProvider>
          <Routes />
        </NotificationsProvider>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
