import React, { useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import styles from './index.module.less';
import { RootState } from '../../../store/reducers';
import { moduleName as localModuleName, updatePlayId as updatePlayIdAction } from '../../../store/ducks/local';
import { Action } from '../../../store';
import Loading from '../Loading';

interface IAudioWave {
  id: string;
  source: string;
  width?: number | string;
  height?: number | string;
  playId: string | null;
  updatePlayId: (value: string | null) => Action;
}

interface IDrawLineSegment {
  ctx: CanvasRenderingContext2D;
  x: number;
  lineHeight: number;
  lineWidth: number;
  isEven: boolean;
}

const filterData = (audioBuffer: AudioBuffer): number[] => {
  const rawData = audioBuffer.getChannelData(0);
  const samples = 70;
  const blockSize = Math.floor(rawData.length / samples);
  const filteredData = [];

  for (let i = 0; i < samples; i++) {
    const blockStart = blockSize * i;
    let sum = 0;

    for (let j = 0; j < blockSize; j++) {
      sum += Math.abs(rawData[blockStart + j]);
    }
    filteredData.push(sum / blockSize);
  }

  return filteredData;
};

const normalizeData = (filteredData: number[]): number[] => {
  const multiplier = Math.max(...filteredData) ** -1;

  return filteredData.map((n) => n * multiplier);
};

const AudioWave: React.FC<IAudioWave> = ({ id, source, width, height, playId, updatePlayId }) => {
  const location = useLocation();
  const currentTimeRef = useRef<number>(0);
  const intervalRef = useRef<NodeJS.Timeout>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const waveRef = useRef<HTMLCanvasElement | null>(null);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const progressWaveRef = useRef<HTMLCanvasElement | null>(null);
  const dataRef = useRef<number[]>([]);
  const eventsRef = useRef<string[]>([]);
  const audio = useMemo(() => new Audio(source), [source]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const audioContext = new AudioContext();

  const draw = (context: React.MutableRefObject<HTMLCanvasElement | null>, color: string, isEmpty?: boolean) => {
    if (!context.current) return;

    const canvas = context.current;

    if (canvas?.width) {
      canvas.width = containerRef.current?.offsetWidth || 0;
    }
    const dpr = window.devicePixelRatio || 1;
    const padding = 20;

    const offsetWidth = canvas.offsetWidth || containerRef.current?.offsetWidth || 0;

    canvas.width = offsetWidth * dpr;
    canvas.height = (canvas.offsetHeight + padding * 2) * dpr;
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    // ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.scale(dpr, dpr);
    ctx.translate(0, canvas.offsetHeight / 2 + padding);

    ctx.lineWidth = 2;
    ctx.strokeStyle = color || '#fff';
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(isEmpty ? canvas?.width : 10, 0);
    ctx.stroke();

    if (isEmpty) return;

    const lineWidth = (offsetWidth - 20) / dataRef.current.length;

    for (let i = 0; i < dataRef.current.length; i++) {
      const x = lineWidth * i + 10;
      let lineHeight = dataRef.current[i] * canvas.offsetHeight - padding;

      if (lineHeight < 0) {
        lineHeight = 0;
      } else if (lineHeight > canvas.offsetHeight / 2) {
        lineHeight = canvas.offsetHeight / 2;
      }
      drawLineSegment({ ctx, x, lineHeight, lineWidth, isEven: !!((i + 1) % 2) });
    }

    ctx.lineWidth = 2;
    ctx.strokeStyle = color || '#fff';
    ctx.beginPath();
    ctx.moveTo(canvas.offsetWidth - 10, 0);
    ctx.lineTo(canvas.offsetWidth, 0);
    ctx.stroke();
  };

  const drawLineSegment = ({ ctx, x, lineHeight, lineWidth, isEven }: IDrawLineSegment) => {
    ctx.beginPath();
    const newHeight = isEven ? lineHeight : -lineHeight;

    ctx.moveTo(x, 0);
    ctx.lineTo(x, newHeight);
    ctx.arc(x + lineWidth / 2, newHeight, lineWidth / 2, Math.PI, 0, isEven);
    ctx.lineTo(x + lineWidth, 0);
    ctx.stroke();
  };

  const resetCanvas = () => {
    draw(waveRef, '#fff', true);
    draw(progressWaveRef, '#fff', true);

    if (!progressRef.current) return;

    progressRef.current.style.width = '0px';
    progressRef.current.style.opacity = '0';
  };

  const drawAudio = (url: string) => {
    resetCanvas();

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
      .then((audioBuffer) => {
        dataRef.current = normalizeData(filterData(audioBuffer));
        draw(waveRef, '#fff');
        draw(progressWaveRef, '#08C1E3');
      })
      .then(() => {
        setIsLoaded(true);
      })
      .catch(() => {
        setErrorMessage("Can't load the audio");
      });
  };

  useEffect(() => {
    if (!source) return;
    drawAudio(source);
  }, [source]);

  useEffect(
    () => () => {
      pauseAudio();
    },
    [],
  );

  useEffect(() => {
    pauseAudio();
  }, [location]);

  const pauseAudio = () => {
    updatePlayId(null);

    setIsPlaying(false);
    audio.pause();
    if (!intervalRef.current) return;

    clearInterval(intervalRef.current);
  };

  const playAudio = (time?: number) => {
    updatePlayId(id);

    if (time) {
      currentTimeRef.current = time;
    }

    setIsPlaying(true);
    audio.play();

    intervalRef.current = setInterval(() => {
      currentTimeRef.current += 100;
      if (!progressRef.current) return;

      progressRef.current.style.width = `${(currentTimeRef.current * 100) / (audio.duration * 1000)}%`;
      if (!Number(progressRef.current.style.opacity)) {
        progressRef.current.style.opacity = '1';
      }

      if (currentTimeRef.current >= audio.duration * 1000) {
        pauseAudio();
        currentTimeRef.current = 0;
        progressRef.current.style.opacity = '0';
      }
    }, 100);
  };

  useEffect(() => {
    if (isPlaying && playId && playId !== id) {
      pauseAudio();
    }
  }, [playId, id, isPlaying]);

  const handleRewind = (event: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current || !progressRef.current) return;

    const x = event.clientX - containerRef.current.getBoundingClientRect().left;
    const percent = (x * 100) / containerRef.current.offsetWidth;

    progressRef.current.style.width = `${percent}%`;

    if (!progressRef.current.style.opacity) {
      progressRef.current.style.opacity = '1';
    }

    const newTime = (audio.duration * 1000 * percent) / 100;

    audio.currentTime = (audio.duration * percent) / 100;
    currentTimeRef.current = newTime;
  };

  const handleMouseDown = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    eventsRef.current = [...eventsRef.current, 'mousedown'];
    if (audio.paused) {
      eventsRef.current = [...eventsRef.current].filter((event) => event !== 'play');
    } else {
      eventsRef.current = [...eventsRef.current, 'play'];
    }
    handleRewind(mouseEvent);
    pauseAudio();
  };

  const handleMouseMove = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    if (eventsRef.current.includes('mousedown')) {
      handleRewind(mouseEvent);
    }
  };

  const handleMouseUp = () => {
    eventsRef.current = [...eventsRef.current].filter((event) => event !== 'mousedown');

    if (eventsRef.current.includes('play')) {
      playAudio();
    }
  };

  const toggleAudio = () => (isPlaying ? pauseAudio() : playAudio());

  return (
    <div className={styles.container} style={{ width, height }} onMouseMove={handleMouseMove}>
      {errorMessage && <Typography className="color-white">{errorMessage}</Typography>}
      {!errorMessage && !isLoaded && <Loading visible absolute size="default" className="background-transparent" />}
      {!errorMessage && isLoaded && (
        <Button id="playButton" type="ghost" className={styles.playButton} onClick={toggleAudio}>
          {isPlaying ? <span className="icon-pause" /> : <span className="icon-play" />}
        </Button>
      )}

      {!errorMessage && (
        <div
          ref={containerRef}
          className={styles.waveContainer}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <canvas ref={waveRef} className={styles.wave} />
          <div ref={progressRef} className={styles.progress}>
            <div style={{ width: containerRef.current?.offsetWidth }} className={styles.waveWrapper}>
              <canvas ref={progressWaveRef} className={styles.wave} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AudioWave.defaultProps = {
  width: '100%',
  height: '100%',
};

const mapStateToProps = (state: RootState) => ({
  playId: state[localModuleName].playId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePlayId: (value: string | null) => dispatch(updatePlayIdAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioWave);
