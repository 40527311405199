import React from 'react';
import clsx from 'clsx';
import { ProColumns } from '@ant-design/pro-table';

import Loading from '../Loading';
import CharacterPerformance from '../CharacterPerformance';
import { ICharacter } from '../../../types/entries';

interface IPageWrapper {
  characterId?: string;
  loading?: boolean;
  children?: React.ReactNode;
  sideColumn?: React.ReactNode;
  header?: React.ReactNode;
  extra?: React.ReactNode;
  extraColumn?: ProColumns<ICharacter>;
}
const PageWrapper: React.FC<IPageWrapper> = ({
  characterId,
  loading,
  children,
  extra,
  sideColumn,
  header,
  extraColumn,
}) => (
  <>
    <Loading
      visible={!!loading}
      absolute
      className="z-index-100 background-transparent backdrop-filter-blur-10 border-radius-16"
    />
    <div className="flex-column gap-16">
      <div className={clsx('grid gap-16', { 'grid-cols-300-auto': !!sideColumn })}>
        {sideColumn}
        <div className="flex-column gap-16">
          {header}
          <CharacterPerformance characterId={characterId} extraColumn={extraColumn} />
          {children}
        </div>
      </div>
      {extra}
    </div>
  </>
);

PageWrapper.defaultProps = {
  characterId: undefined,
  loading: false,
  children: undefined,
  sideColumn: undefined,
  header: undefined,
  extra: undefined,
  extraColumn: undefined,
};

export default PageWrapper;
