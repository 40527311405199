import React from 'react';

import Content from '../../../../components/Pages/Customer/Characters/Knowledge';
import { displayName } from '../../../../config';
import Layout from '../../../../components/Layout/Main';

const CharacterKnowledge = (): JSX.Element => {
  document.title = `${displayName}: Character Knowledge`;

  return (
    <Layout title="Character Knowledge" withBackButton>
      <Content />
    </Layout>
  );
};

export default CharacterKnowledge;
