export const displayName = 'Neural Voice';
export const name = 'neural-voice';

export const pageSize = 10;
export const paginationSize = 6;
export const getUserDelay = 2000;

export const userLifetime = 14400000;

export const alertDelaySuccess = 2000;
export const alertDelayWarning = 5000;
export const alertDelayError = 10000;

export const alertDelayConfirmationEmail = 60000;
export const alertDelayChangeEmail = 60000;
export const alertDelayNewPassword = 60000;
export const alertDelayForgotPassword = 60000;
export const alertDelayResendEmail = 60000;

export const firebaseConfig = {
  name: 'nv-app',
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
