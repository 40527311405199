import React from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Button, Tabs } from 'antd';

import Introduction from './Introduction';
import { ICharacter } from '../../../../../types/entries';
import { RootState } from '../../../../../store/reducers';
import { moduleName as charactersModuleName } from '../../../../../store/ducks/characters';
import ContentCard from '../../../../Common/ContentCard';
import PageWrapper from '../../../../Common/PageWrapper';
import TextUpload from './TextUpload';
import LinkUpload from './LinkUpload';
import FileUpload from './FileUpload';

interface ICharacterDetails {
  loading: boolean;
  character: ICharacter | null;
}
const ManageCharacter: React.FC<ICharacterDetails> = ({ loading, character }) => {
  const { characterId } = useParams();

  const tabs = [
    {
      label: 'Introduction',
      component: <Introduction />,
    },
    {
      label: 'Product',
      component: <Introduction />,
    },
    {
      label: 'Service',
      component: <Introduction />,
    },
    {
      label: 'Contact Information',
      component: <Introduction />,
    },
    {
      label: 'Actions',
      component: <Introduction />,
    },
    {
      label: 'Sources',
      component: <Introduction />,
    },
  ];

  return (
    <PageWrapper
      characterId={characterId}
      loading={loading}
      extraColumn={{
        width: 180,
        align: 'center',
        dataIndex: 'id',
        renderText: (id) => (
          <Link to={`/characters/${id}`} className="text-decoration-none">
            View Details
          </Link>
        ),
      }}
      extra={
        <div className="flex-column gap-40">
          <ContentCard>
            <div className="flex-row gap-20">
              <TextUpload name={character?.name || 'character'} />
              <LinkUpload name={character?.name || 'character'} />
              <FileUpload name={character?.name || 'character'} />
            </div>
          </ContentCard>
          <ContentCard>
            <div className="w-full">
              <Tabs
                defaultActiveKey="1"
                type="card"
                items={tabs.map((tab) => ({
                  label: tab.label,
                  key: tab.label,
                  children: tab.component,
                }))}
              />
            </div>
          </ContentCard>
          <Button>Back to Character's Page</Button>
        </div>
      }
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[charactersModuleName].loading,
  character: state[charactersModuleName].character,
});

export default connect(mapStateToProps)(ManageCharacter);
