import React from 'react';

import { Tooltip, Typography } from 'antd';
import ContentCard from '../../ContentCard';
import { capitalizeFirstLetter } from '../../../../utils';
import styles from '../index.module.less';

type ColorType = 'positive' | 'neutral' | 'negative';

interface IOverallSentiment {
  title?: string;
  details?: string;
  sentiment?: {
    positive: number;
    neutral: number;
    negative: number;
  };
  radius?: number;
  width?: number;
  height?: number;
}

const OverallSentiment: React.FC<IOverallSentiment> = ({ title, sentiment }) => {
  const colors = {
    positive: getComputedStyle(document.documentElement).getPropertyValue('--color-success'),
    neutral: getComputedStyle(document.documentElement).getPropertyValue('--color-info'),
    negative: getComputedStyle(document.documentElement).getPropertyValue('--color-error'),
  };

  const totalCalls = Object.values(sentiment || {}).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0,
  );

  return (
    <ContentCard title={title} align="center">
      <div className={styles.chartRow}>
        {Object.entries(sentiment || {}).map(([key, value]) => (
          <Tooltip key={`${key}-chart`} title={`${capitalizeFirstLetter(key)}: ${value}`}>
            <div
              className={styles.chartItem}
              style={{ background: colors[key as ColorType], width: `${(value / totalCalls) * 100}%` }}
            />
          </Tooltip>
        ))}
      </div>
      <div className={styles.legendRow}>
        {Object.keys(sentiment || {}).map((key) => (
          <div key={`${key}-legend`} className={styles.legendItem}>
            <div className={styles.legendColor} style={{ background: colors[key as ColorType] }} />
            <Typography className="color-white fs-12">{capitalizeFirstLetter(key)}</Typography>
          </div>
        ))}
      </div>
    </ContentCard>
  );
};

OverallSentiment.defaultProps = {
  title: undefined,
  details: undefined,
  sentiment: undefined,
  width: undefined,
  height: undefined,
  radius: undefined,
};

export default OverallSentiment;
