import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Tile from './Tile';
import { RootState } from '../../../../store/reducers';
import {
  getCharacters as getCharactersAction,
  IParams,
  moduleName as charactersModuleName,
} from '../../../../store/ducks/characters';
import { ICharacter } from '../../../../types/entries';
import { Action } from '../../../../store';
import Loading from '../../../Common/Loading';
import Pagination from '../../../Common/Pagination';
import { useContextNotifications } from '../../../../context/notifications';

interface ICharacters {
  loading: boolean;
  characters: ICharacter[];
  total: number;
  getCharacters: (params?: IParams) => Action;
}

const pageSize = 4;

const Characters: React.FC<ICharacters> = ({ loading, characters, total, getCharacters }) => {
  const [page, setPage] = useState<number>(1);
  const { openConfirm } = useContextNotifications();

  useEffect(() => {
    getCharacters({ page, size: pageSize });
  }, [page]);

  return (
    <div className="flex-column gap-40">
      <Loading visible={loading} absolute />
      <Button
        type="primary"
        onClick={() =>
          openConfirm?.({
            content: (
              <Typography className="fs-16 color-white">
                Please contact{' '}
                <a className="fs-16" href="mailto: support@neural-voice.ai">
                  support@neural-voice.ai
                </a>{' '}
                if you would like to add an additional character to your account
              </Typography>
            ),
            okCancel: false,
            okText: 'Ok',
          })
        }
      >
        + New Character
      </Button>
      <div className="grid grid-cols-3 gap-24">
        {characters.map((character) => (
          <Tile key={character.id} character={character} />
        ))}
      </div>
      <Pagination page={page} onChange={setPage} total={total} pageSize={pageSize} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[charactersModuleName].loading,
  characters: state[charactersModuleName].charactersList,
  total: state[charactersModuleName].charactersTotal,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCharacters: (params?: IParams) => dispatch(getCharactersAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Characters);
