import React from 'react';
import { Typography } from 'antd';

import ContentCard from '../../ContentCard';

interface IAnalyticsTile {
  value: string | number;
  label: string;
  icon: React.ReactNode;
}
const AnalyticsTile: React.FC<IAnalyticsTile> = ({ value, label, icon }) => (
  <ContentCard>
    <div className="flex-row flex-justify-space-between flex-align-center">
      {icon}
      <div className="flex-column gap-4 flex-align-center">
        <Typography.Text className="fs-32 color-blue">{value}</Typography.Text>
        <Typography.Text className="fs-18 color-white">{label}</Typography.Text>
      </div>
    </div>
  </ContentCard>
);

export default AnalyticsTile;
