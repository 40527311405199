import React from 'react';

import Content from '../../../components/Pages/Customer/Characters';
import { displayName } from '../../../config';
import Layout from '../../../components/Layout/Main';

const Characters = (): JSX.Element => {
  document.title = `${displayName}: Characters`;

  return (
    <Layout title="My Characters">
      <Content />
    </Layout>
  );
};

export default Characters;
