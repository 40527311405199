import React from 'react';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Avatar, Badge, Button, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import Simple from '../Simple';
import MenuCommon from './Menu';
import { RootState } from '../../../store/reducers';
import { moduleName as authModuleName, signOut as signOutAction } from '../../../store/ducks/auth';

import styles from './index.module.less';
import { IUser } from '../../../types/entries';

interface IMain {
  children: React.ReactNode;
  title?: string;
  extra?: React.ReactNode;
  withBackButton?: boolean;
  user: IUser | null;
  signOut: () => Action;
}

const Main: React.FC<IMain> = ({ children, title, extra, withBackButton, user, signOut }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Simple>
      <MenuCommon />
      <div className={styles.container}>
        {title && (
          <div className={styles.header}>
            <div className="flex-row gap-10 flex-align-center flex-justify-start">
              {withBackButton && (
                <Button type="text" icon={<span className="icon-arrow_back color-white fs-32" />} onClick={goBack} />
              )}
              <Typography.Title className={styles.title}>{title}</Typography.Title>
            </div>
            <div className="flex-row gap-12 flex-align-center flex-justify-start">
              {extra}
              <Button type="text" className={styles.notificationsButton}>
                <Badge count={0} size="small" className={styles.notifications} showZero={false}>
                  <span className="icon-notifications" />
                </Badge>
              </Button>
              <div className="flex-column gap-4">
                <Typography.Text className={styles.username}>{`${user?.firstName} ${user?.lastName}`}</Typography.Text>
                <Button type="text" className={styles.logout} onClick={signOut}>
                  Log Out
                </Button>
              </div>
              <Avatar size={60} icon={<UserOutlined />} src={user?.avatar} />
            </div>
          </div>
        )}
        <div className={styles.contentWrapper}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Simple>
  );
};

Main.defaultProps = {
  title: undefined,
  extra: undefined,
  withBackButton: false,
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signOut: () => dispatch(signOutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
