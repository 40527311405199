export const copyTextToClipboard = (text: string) =>
  'clipboard' in navigator
    ? navigator.clipboard.writeText(text)
    : new Promise(() => {
        document.execCommand('copy', true, text);
      });

export const prettyTextWithUnderscores = (text: string) =>
  text
    ? text
        .split('_')
        .map((word) => (word ? `${word[0].toUpperCase()}${word.slice(1, word.length)}` : ''))
        .join(' ')
    : '';
