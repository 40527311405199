import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import { RootState } from '../../../../store/reducers';
import { Action } from '../../../../store';
import { getClientsList as getClientsListAction, moduleName as clientModuleName } from '../../../../store/ducks/client';
import ContentCard from '../../../Common/ContentCard';
import { JsonResult } from '../../../../types';

interface IDashboard {
  loading: boolean;
  clients: JsonResult[] | null;
  getClientsList: () => Action;
}

const Dashboard: React.FC<IDashboard> = ({ loading, clients, getClientsList }) => {
  useEffect(() => {
    getClientsList();
  }, []);

  return (
    <ContentCard loading={loading}>
      <div className="flex-column gap-16">
        {clients?.map((client: JsonResult) => (
          <Typography key={client.id} className="color-white">
            {client.id}
          </Typography>
        ))}
      </div>
    </ContentCard>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[clientModuleName].loading,
  clients: state[clientModuleName].clients,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getClientsList: () => dispatch(getClientsListAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
