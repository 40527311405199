import React, { CSSProperties, ReactNode } from 'react';
import { Card, Tooltip, Typography } from 'antd';
import clsx from 'clsx';

import styles from './index.module.less';

interface IContentCard extends CSSProperties {
  children: ReactNode;
  extra?: ReactNode;
  title?: string;
  loading?: boolean;
  details?: string;
  align?: 'left' | 'center' | 'right';
}
const ContentCard: React.FC<IContentCard> = (props) => {
  const { children, extra, loading, details, title, align, ...style } = props;

  return (
    <Card className={styles.card} style={style} loading={loading}>
      <div className={styles.cardContent} style={{ alignItems: align }}>
        {(title || extra) && (
          <div className="w-full flex-row flex-justify-space-between flex-align-center gap-20">
            <Typography.Title level={2} className={clsx(styles.title, styles[`align-${align || 'left'}`])}>
              {title}
            </Typography.Title>
            {extra}
          </div>
        )}
        {details && (
          <div className={styles.details}>
            <Tooltip title={details}>
              <span className={clsx('icon-info', styles.icon)} />
            </Tooltip>
          </div>
        )}
        {children}
      </div>
    </Card>
  );
};

ContentCard.defaultProps = {
  title: undefined,
  loading: false,
  details: undefined,
  align: 'left',
  extra: undefined,
};
export default ContentCard;
