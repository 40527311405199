import React from 'react';
import { Button, Modal } from 'antd';

import styles from '../index.module.less';

export interface IUploadModal {
  title: string;
  isVisible: boolean;
  onOk: () => void;
  onClose: () => void;
  children: React.ReactNode;
}

const UploadModal: React.FC<IUploadModal> = ({ title, isVisible, onOk, onClose, children }) => (
  <Modal title={title} open={isVisible} onCancel={onClose} footer={null}>
    <div className={styles.modalContent}>
      <div className={styles.modalBody}>{children}</div>
      <div className={styles.modalFooter}>
        <Button type="primary" onClick={onOk}>
          Continue
        </Button>
      </div>
    </div>
  </Modal>
);

export default UploadModal;
