import { SagaIterator } from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { saga as local } from './ducks/local';
import { saga as auth } from './ducks/auth';
import { saga as characters } from './ducks/characters';
import { saga as records } from './ducks/records';
import { saga as customer } from './ducks/customer';
import { saga as client } from './ducks/client';

export default function* Saga(): SagaIterator {
  yield spawn(local);
  yield spawn(auth);
  yield spawn(characters);
  yield spawn(records);
  yield spawn(customer);
  yield spawn(client);
}
