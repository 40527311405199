import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { Action } from '../index';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse } from './common';
import { ICharacter, ICharacterPerformance, ICharactersStats } from '../../types/entries';
import { CUSTOMER_ID } from '../constants';
import { fetchAuthSaga } from './auth';

/**
 * Constants
 * */
export const moduleName = 'characters';
const prefix = `${appName}/${moduleName}`;

export const CHARACTERS_START = `${prefix}/CHARACTERS_START`;
export const CHARACTERS_STATS_START = `${prefix}/CHARACTERS_STATS_START`;
export const CHARACTERS_SUCCESS = `${prefix}/CHARACTERS_SUCCESS`;
export const CHARACTERS_ERROR = `${prefix}/CHARACTERS_ERROR`;
export const CHARACTERS_ERROR_RESET = `${prefix}/CHARACTERS_ERROR_RESET`;

export const GET_CHARACTERS = `${prefix}/GET_CHARACTERS`;
export const GET_CHARACTERS_STATS = `${prefix}/GET_CHARACTERS_STATS`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  statsLoading: boolean;
  error: Error | null;
  charactersList: ICharacter[];
  charactersTotal: number;
  character: ICharacter | null;
  stats: ICharactersStats | null;
}

const localState: State = {
  loading: false,
  statsLoading: false,
  error: null,
  charactersList: [],
  character: null,
  charactersTotal: 0,
  stats: null,
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case CHARACTERS_START:
      return { ...state, loading: true, error: null };
    case CHARACTERS_STATS_START:
      return { ...state, statsLoading: true, error: null };
    case CHARACTERS_SUCCESS:
      return { ...state, loading: false, ...payload };
    case CHARACTERS_ERROR:
      return { ...state, loading: false, error: payload };
    case CHARACTERS_ERROR_RESET:
      return { ...state, loading: false, error: null };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */

/**
 * Action Creators
 * */

export interface IParams {
  id?: string;
  page?: number;
  size?: number;
}

export const getCharacters = (payload?: IParams): Action => ({
  type: GET_CHARACTERS,
  payload,
});

export const getCharactersStats = (): Action => ({
  type: GET_CHARACTERS_STATS,
});

/**
 * Sagas
 */
export function* getCharactersSaga({ payload: { id } }: { payload: IParams }): Generator {
  yield put({
    type: CHARACTERS_START,
  });

  const url = `${process.env.REACT_APP_TRANSCRIPTS_API_URL}${
    id ? `/assistant/${id}/character-details` : `/customer/${CUSTOMER_ID}/character-details`
  }`;

  const response = (yield call(fetchAuthSaga, url)) as FetchResponse;

  yield defaultResponseProcessing(response, CHARACTERS_SUCCESS, CHARACTERS_ERROR, false, (data) =>
    id
      ? {
          charactersList: [
            {
              id: data.assistant_id,
              key: data.assistant_id,
              image: data.assistant_avatar_url,
              name: data.assistant_name,
              purpose: data.assistant_purpose,
              isActive: true,
              rating: data.percentPositive,
              emptyCount: data.emptyCount,
              successCount: data.successCount,
              totalCount: data.totalCount,
            },
          ],
          character: {
            id: data.assistant_id,
            key: data.assistant_id,
            image: data.assistant_avatar_url,
            name: data.assistant_name,
            purpose: data.assistant_purpose,
            isActive: true,
            rating: data.percentPositive,
            emptyCount: data.emptyCount,
            successCount: data.successCount,
            totalCount: data.totalCount,
          },
          stats: {
            callsHandled: data.successCount,
            sentimental: {
              positive: data.sentiment.pos,
              neutral: data.sentiment.neu,
              negative: data.sentiment.neg,
            },
          },
          charactersTotal: 1,
        }
      : {
          charactersList: data.character_performances.map((character: ICharacterPerformance) => ({
            id: character.assistant_id,
            key: character.assistant_id,
            image: character.assistant_avatar_url,
            name: character.assistant_name,
            purpose: character.assistant_purpose,
            isActive: true,
            rating: character.percent_positive,
            emptyCount: character.empty_call_count,
            successCount: character.successful_call_count,
            totalCount: character.total_calls,
          })),
          stats: {
            callsHandled: data.total_successful_calls,
            callsDuration: data.call_dur_avg,
            sentimental: {
              positive: data.pieChart.pos,
              neutral: data.pieChart.neu,
              negative: data.pieChart.neg,
            },
          },
          charactersTotal: data.length,
        },
  );
}

export function* getCharactersStatsSaga(): Generator {
  yield put({
    type: CHARACTERS_STATS_START,
  });

  const response = (yield call(
    fetchAuthSaga,
    `${process.env.REACT_APP_TRANSCRIPTS_API_URL}/customer/${CUSTOMER_ID}/character-details`,
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CHARACTERS_SUCCESS, CHARACTERS_ERROR, false, (data) => ({
    stats: {
      callsHandled: data.total_successful_calls,
      callsDuration: data.call_dur_avg,
      sentimental: {
        positive: data.pieChart.pos,
        neutral: data.pieChart.neu,
        negative: data.pieChart.neg,
      },
    },
    statsLoading: false,
  }));
}

export function* saga(): Generator {
  yield takeLatest(GET_CHARACTERS, cancelableLocationSaga.bind(null, getCharactersSaga, CHARACTERS_ERROR, false));
  yield takeLatest(GET_CHARACTERS_STATS, cancelableLocationSaga.bind(null, getCharactersStatsSaga, CHARACTERS_ERROR));
  yield takeLatest(CHARACTERS_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: CHARACTERS_ERROR_RESET,
    });
  });
}
