import React from 'react';
import { Button, Pagination as AntdPagination, PaginationProps } from 'antd';

interface IPagination {
  page: number;
  total: number;
  pageSize?: number;
  onChange: (page: number) => void;
}
const Pagination: React.FC<IPagination> = ({ page, onChange, total, pageSize }) => {
  const itemRender: PaginationProps['itemRender'] = (pageNumber, type, originalElement) => {
    switch (type) {
      case 'prev':
        return <Button type="text">Prev</Button>;
      case 'next':
        return <Button type="text">Next</Button>;
      case 'jump-prev':
        return <Button type="text">{originalElement}</Button>;
      case 'jump-next':
        return <Button type="text">{originalElement}</Button>;
      default:
        return <Button type="text">{pageNumber}</Button>;
    }
  };

  return (
    <div className="w-full flex-row flex-justify-center">
      <AntdPagination
        current={page}
        onChange={onChange}
        total={total}
        pageSize={pageSize}
        itemRender={itemRender}
        hideOnSinglePage
        showSizeChanger={false}
      />
    </div>
  );
};

Pagination.defaultProps = {
  pageSize: 10,
};
export default Pagination;
