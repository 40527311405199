import React, { useEffect, useState } from 'react';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Form, Image, Input, Row, Typography } from 'antd';
import { useGoogleLogin } from '@react-oauth/google';
import clsx from 'clsx';

import { login, password } from '../../../../utils/inputRules';
import { RootState } from '../../../../store/reducers';
import { ISignIn, moduleName as authModuleName, signIn as signInAction } from '../../../../store/ducks/auth';

import styles from '../index.module.less';
import { IconGoogle } from '../../../Common/Icon';

interface ISignUpComponent {
  loading: boolean;
  authorized: boolean;
  error: Error | null;
  signIn: (data: ISignIn) => Action;
}
const SignUp: React.FC<ISignUpComponent> = ({ loading, authorized, signIn }) => {
  const navigate = useNavigate();
  const [isDefaultSignIn, setIsDefaultSignIn] = useState<boolean>(false);

  useEffect(() => {
    if (!authorized) return;

    navigate('/');
  }, [authorized]);

  const handleSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // eslint-disable-next-line
      console.log(tokenResponse);
    },
  });

  return (
    <div className={clsx(styles.container, { [styles.signUp]: isDefaultSignIn })}>
      <Image width={273} height={42} src="/logo.png" preview={false} />
      <Typography.Title className={styles.title}>Sign Up to Neural Voice</Typography.Title>
      {isDefaultSignIn ? (
        <Form layout="vertical" className={clsx(styles.form, styles.signUpForm)} onFinish={signIn} labelWrap>
          <Row gutter={20}>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item name="firstName" label="First Name" rules={login}>
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item name="lastName" label="Last Name" rules={login}>
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="email" label="Business Email" rules={login}>
            <Input size="large" />
          </Form.Item>
          <Row gutter={20}>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item name="password" label="Password" rules={password}>
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item
                name="passwordConfirm"
                label="Confirm Password"
                dependencies={['password']}
                rules={[
                  ...password,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item noStyle>
            <Button type="text" size="large" htmlType="submit" className={styles.submitButton} loading={loading}>
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>
          <Button
            size="large"
            type="text"
            onClick={() => handleSignUp()}
            className={styles.googleButton}
            loading={loading}
            icon={<IconGoogle />}
          >
            Sign up with Google
          </Button>
          <div className={styles.divider}>
            <div className={styles.line} />
            <Typography className={styles.text}>or</Typography>
            <div className={styles.line} />
          </div>
          <Button
            size="large"
            type="text"
            onClick={() => setIsDefaultSignIn(true)}
            className={styles.submitButton}
            loading={loading}
          >
            Continue with email
          </Button>
        </>
      )}

      <div className={styles.footer}>
        <Typography className={styles.link}>
          Already have an account?{' '}
          <Link className={styles.signIn} to="/sign-in">
            Sign In
          </Link>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  authorized: state[authModuleName].authorized,
  error: state[authModuleName].error,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (data: ISignIn) => dispatch(signInAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
