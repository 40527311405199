import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Image, Input, Typography } from 'antd';

import styles from '../index.module.less';
import { login } from '../../../../utils/inputRules';

interface IForgotPasswordData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const handleSubmit = (values: IForgotPasswordData) => {
    // eslint-disable-next-line
    console.log(values);
  };

  return (
    <div className={styles.container}>
      <Image width={273} height={42} src="/logo.png" preview={false} />
      <div>
        <Typography.Title className={styles.title}>Forgot Password?</Typography.Title>
        <Typography.Text className={styles.description}>
          Enter your emails and we will send you instructions to reset your password.
        </Typography.Text>
      </div>
      <Form layout="vertical" className={styles.form} onFinish={handleSubmit} labelWrap>
        <Form.Item name="email" label="Email" rules={login}>
          <Input size="large" />
        </Form.Item>
        <Form.Item noStyle>
          <Button type="text" size="large" htmlType="submit" className={styles.submitButton}>
            Send Reset Instructions
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        <Link className={styles.link} to="/sign-in">
          Return to Sign In
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
